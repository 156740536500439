import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { down } from '@thomaseckhardt/styled-breakpoints'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'
import Article, { VARIANT_TEXT_INTENDED } from '../modules/Article'
import JumpNav from '../components2/JumpNav'

const Main = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 1fr;

  > * {
    grid-column: 1 / span 16;
    min-height: 70vh;
  }

  > *:nth-child(2) {
    grid-row: 1;
    min-height: 0;
  }
`

const MainContent = styled.article`
  grid-column: 1 / span 16;
  min-height: 70vh;
`

const JumpNavContainer = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: 1 / span 3;
  z-index: 1;

  ${down('laptop')} {
    display: none;
  }
`

const StyledJumpNav = styled(JumpNav)`
  position: sticky;
  top: 0;
  padding-top: ${props => props.theme.px('space.11')};
`

const Page = ({ data }) => {
  const postNode = {
    title: `Kundenservice - ${config.siteTitle}`,
  }

  const blockIntro = {
    leading: 10,
    variant: VARIANT_TEXT_INTENDED,
    id: `kundenservice`,
    headline: `Unser Kundenservice`,
    lead: `Wir bieten Ihnen Komplettlösungen aus einer Hand! Finden Sie hier eine Übersicht unseres umfassenden Services.`,
  }

  const blocks = [
    {
      leading: 10,
      variant: VARIANT_TEXT_INTENDED,
      id: `sichtsonnenschutz`,
      topline: `Sicht- und Sonnenschutz`,
      headline: `Alles für Ihre Gardinen und Sonnenschutz`,
      copytext: `<p>In unserem hauseigenen Nähatelier fertigen wir die Gardinen passend zu Ihrem Wohnraum an. Dafür messen wir bei Ihnen zuhause aus und Dekorieren auf Wunsch die fertige Gardine.
      Auch bei der Montage von Sonnenschutz stehen wir Ihnen gerne mit unserem Erfahrung zur Seite und bringen alles fachgerecht an.</p>`,
    },{
      leading: 10,
      variant: VARIANT_TEXT_INTENDED,
      id: `boden`,
      topline: `Bodenbeläge`,
      headline: `Wir verlegen, schleifen und versiegeln Ihren Boden`,
      copytext: `<p>Die Idee eines neuen Bodenbelages ist schnell geboren, doch wie kann der unkompliziert in meine jetzigen Wohnräume verlegt werden? Wir beraten Sie umfassend zum Bodenaufbau, Entfernen alte Beläge und verlegen Ihren neuen Belag. Und auch zum Thema Trittschalldämmung können wir Sie mit Rat und Tat unterstützen.</p>`,
    }, {
      leading: 10,
      variant: VARIANT_TEXT_INTENDED,
      id: `wandgestaltung`,
      topline: `Wandgestaltung`,
      headline: `Wir bieten Farben und Malerarbeiten`,
      copytext: `<p>Auch beim Thema Wandgestaltung können Sie sich auf uns verlassen. Wir fertigen Ihre Wandbespannung und bringen Sie vor Ort an. Zudem bieten wir alle Arten von Malerarbeiten von Tapezieren bis Streichen an.
      Sie suchen eine ganz bestimmte Farbe oder haben ein Farbmuster, zu dem Sie die gleiche oder passende Farbe suchen? Mit unserem Farben-Mischservice von Caparol können wir unzählige Farbnuancen für Sie mischen.</p>`,
    }, {
      leading: 10,
      variant: VARIANT_TEXT_INTENDED,
      id: `verleih`,
      topline: `Verleih`,
      headline: `Vermietung von Reinigungsgeräten`,
      copytext: `<p>Bevor die Fleckenschere zum Einsatz kommen muss, sprechen Sie uns gerne an. Wir bieten Reinigungsgeräte zum Verleih für Teppichböden oder Möbel und beraten Sie gerne zur schonenden Behandlung.</p>`,
    }, {
      leading: 10,
      variant: VARIANT_TEXT_INTENDED,
      id: `moebelstoffe`,
      topline: `Möbelstoffe`,
      headline: `Informationsbroschüre`,
      copytext: `<p>Laden Sie sich unsere Informationsbroschüre über Möbelstoffe herunter und erfahren Sie mehr über Gebrauchseigenschaften, Pflege und vieles mehr.</p>`,
      actions: [
        {
          variant: 'secondary',
          href: '/dokumente/d1-moebel-broschuere.pdf',
          label: 'Informationsbroschüre Möbelstoffe (PDF)'
        }
      ]
    }
  ]

  const jumpNavItems = [].concat(
    {
      label: blockIntro.headline,
      href: `#${blockIntro.id}`,
      active: true
    },
    blocks.map(block => ({
      label: block.topline,
      href: `#${block.id}`,
    }))
  )

  return (
    <Layout>
      <Helmet>
        <title>{`Kundenservice - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="kundenservice" customTitle />
      
      <Main>
        <JumpNavContainer>
          <StyledJumpNav items={jumpNavItems} />
        </JumpNavContainer>
        <MainContent>
          <Article as="header" {...blockIntro}></Article>
          {blocks.map(block => {
            return <Article as="article" key={block.id} {...block}></Article>
          })}
        </MainContent>
      </Main>
    </Layout>
  )
}

export default Page
